<template>
  <div class="all_main_wrap">
    <HeaderBox>
      <template #title> 自主学习 </template>
      <template #input>
        <a-input v-model="name" placeholder="请输入关键字" />
        <!-- <a-date-picker placeholder="请选择开课时间" /> -->
        <a-button
          type="primary"
          class="btn"
          :loading="loading"
          @click="search()"
          >搜索</a-button
        >
      </template>
    </HeaderBox>
    <!-- 表格列表 -->
    <div class="table-box">
      <div class="tab">
        <a-tabs v-model="courseId" @change="callback">
          <a-tab-pane
            :key="item.courseId"
            :tab="item.courseName"
            v-for="item in courseData.slice(0, 4)"
          ></a-tab-pane>
        </a-tabs>
        <p :class="{ elseCourse: !isElse }" @click="elseCourseShow = true">
          其他课程
        </p>
      </div>

      <a-table
        class="table-template"
        :rowKey="(item, index) => index"
        :columns="columns"
        :data-source="tableData"
        :loading="loading"
        @change="onPage"
        :pagination="{
          total: total,
          current: pageNumber,
          defaultPageSize: pageSize,
          showSizeChanger: true,
          showTotal: function (total, range) {
            return `共${total}条`;
          },
        }"
      >
        <template slot="index" slot-scope="item, row, i">
          <div style="text-align: center">
            {{ (pageNumber - 1) * pageSize + i + 1 }}
          </div>
        </template>

      <!-- 手机号 -->
        <template slot="mobile" slot-scope="text">
          <div style="text-align: center">
            {{ text ? $encryption('empty','phone',text) : '-' }}
          </div>
        </template>


        <template slot="validTime" slot-scope="item, row">
          <div style="text-align: center">
            {{ row.startTime + "-" + row.endTime }}
          </div>
        </template>
        <template slot="payer" slot-scope="item">
          <div style="text-align: center">
            {{ item == 1 ? "个人" : "机构" }}
          </div>
        </template>
        <template slot="studyProgress" slot-scope="item">
          <div style="text-align: center">{{ item * 100 }}%</div>
        </template>

        <template slot="operation" slot-scope="item, row">
          <div class="btn_router_link">
            <a @click="getperiod(row)">更改课程有效期</a>
            <span v-if="row.examCertificatePicture" style="margin: 0 5px"
              >|</span
            >
            <div
              v-if="row.examCertificatePicture"
              style="position: relative; overflow: hidden"
            >
              <a>查看证书</a>

              <viewer>
                <img
                  style="
                    position: absolute;
                    top: -10px;
                    left: 0;
                    opacity: 0;
                    cursor: pointer;
                  "
                  class="inneer_img"
                  :src="row.examCertificatePicture"
                />
              </viewer>
            </div>
            <span style="margin: 0 5px">|</span>
            <a @click="getHistory(row.userId)">历史成绩</a>
            <span style="margin: 0 5px">|</span>
            <a @click="onOpenExamModal(row.userId)">修改考试次数</a>
          </div>
        </template>
      </a-table>
    </div>
    
    <!-- <div class="allCourse" @mouseenter="showAllCourse">
      <p class="text">全部课程</p>
      <div class="elseCourseModel" :class="{closeModel: !elseCourseShow}">
        <div class="header">
          <a-icon type="close" @click="elseCourseShow=false,reset()" class="closeBtn"/>
          <p class="title">其他课程</p>
          <div class="search">
            <a-input v-model="courseName" placeholder="请输入关键字" />
            <a-button
              type="primary"
              class="btn"
              @click="filterCourseList()"
              >搜索</a-button
            >
          </div>
        </div>
        <ul class="courseList">
          <div class="none" v-if="!modelSearchList.length">
            暂无数据~
          </div>
          <li
            :title="item.courseName"
            :class="{active: item.courseId == courseId}"
            @click="elseCourseShow=false,reset(),callback(item.courseId)"
            v-for="item in elseCourseList"
            :key="item.courseId">
            {{item.courseName}}
          </li>
        </ul>
        <a-pagination v-model="current" @change="changeElseCourse" :hideOnSinglePage="true" :defaultPageSize="30" :total="modelSearchList.length" show-less-items />
      </div>
    </div> -->
    <!-- 其他课程 -->
    <a-modal
      v-model="elseCourseShow"
      class="elseCourseModel"
      align="left"
      width="1200px"
      :footer="null"
      @cancel="reset"
    >
      <template #title>
        <div class="header">
          <p class="title">其他课程</p>
          <div class="search">
            <a-input v-model="courseName" placeholder="请输入关键字" />
            <a-button type="primary" class="btn" @click="filterCourseList()"
              >搜索</a-button
            >
          </div>
        </div>
      </template>
      <ul class="courseList">
        <div class="none" v-if="!modelSearchList.length">暂无数据~</div>
        <li
          :title="item.courseName"
          :class="{ active: item.courseId == courseId }"
          @click="(elseCourseShow = false), callback(item.courseId)"
          v-for="item in elseCourseList"
          :key="item.courseId"
        >
          {{ item.courseName }}
        </li>
      </ul>
      <a-pagination
        v-model="current"
        @change="changeElseCourse"
        :hideOnSinglePage="true"
        :defaultPageSize="30"
        :total="modelSearchList.length"
        show-less-items
      />
    </a-modal>

    <!-- 更改有效期 -->
    <a-modal v-model="isValidityShow" align="center" title="更改课程有效期">
      <div class="all_content_box">
        <div class="line-item rows">
          <a-row class="rows">
            <a-col span="6" class="left"> <span>更改有效期</span></a-col>
            <a-col span="18" class="right">
              <a-range-picker
                v-model="timedata"
                format="YYYY-MM-DD HH:mm:ss"
                :show-time="{ format: 'HH:mm:ss' }"
                @change="onChange"
                class="right"
              />
            </a-col>
          </a-row>
        </div>
        <a-row class="rows">
          <a-col span="6" class="left"> <span>备注:</span></a-col>
          <a-col span="18" class="right">
            <a-textarea
              v-model="remark"
              placeholder="请填写备注"
              :auto-size="{ minRows: 3, maxRows: 6 }"
            />
          </a-col>
        </a-row>
      </div>
      <template slot="footer">
        <a-button type="primary" :loading="PreventLoad" @click="selchange()"
          >确认</a-button
        >
        <a-button @click="isValidityShow = false">取消</a-button>
      </template>
    </a-modal>
    <!-- 操作记录 -->
    <a-modal
      v-model="isRecordShow"
      okText="关闭"
      align="center"
      title="考试所获证书"
    >
      <img
        src="https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fimg11.cntrades.com%2F201609%2F09%2F11-41-08-74-1097160.jpg&refer=http%3A%2F%2Fimg11.cntrades.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1649992196&t=4fc108600349931f397698dba185abd8"
        style="width: 100%"
        alt=""
      />
      <template slot="footer">
        <a-button type="primary" @click="isRecordShow = false">关闭</a-button>
      </template>
    </a-modal>

    <!-- 历史成绩 -->
    <a-modal
      v-model="achievementsShow"
      align="center"
      title="历史成绩"
      :footer="null"
    >
      <a-table
        class="table-template"
        style="margin: 0"
        :rowKey="(item) => item.id"
        :columns="columns3"
        :data-source="achievementsList"
      >
        <template slot="index" slot-scope="item, row, i">
          <div style="text-align: center">
            {{ (pageNumber - 1) * pageSize + i + 1 }}
          </div>
        </template>
      </a-table>
    </a-modal>

    <!-- 修改考试次数 -->
    <a-modal v-model="isNumberShow" align="center"  title="修改考试次数" >
      <div class="all_content_box">
        <div class="line-item">
          <span class="all_left_name">次数：</span>
          <a-input-number class="right" v-model="examNumForm.examCount" :precision="0" placeholder="请输入考试次数"  />
        </div>
        <div class="line-item">
          <span class="all_left_name">备注：</span>
          <a-input class="right" v-model="examNumForm.remark" placeholder="备注" />
        </div>
      </div>
      <template slot="footer">
        <a-button type="primary" @click="onModify" :loading="PreventLoad">确认</a-button>
        <a-button  @click="isNumberShow=false">取消</a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
const columns = [
  {
    title: "序号",
    width: "80px",
    align: "center",
    dataIndex: "index",
    scopedSlots: { customRender: "index" },
  },
  {
    title: "姓名",
    align: "center",
    dataIndex: "name",
  },
  {
    title: "手机号",
    align: "center",
    dataIndex: "mobile",
    scopedSlots: { customRender: "mobile" },
  },
  {
    title: "有效期",
    align: "center",
    dataIndex: "validTime",
    scopedSlots: { customRender: "validTime" },
  },
  // {
  //   title: "有效期状态",
  //   align: "center",
  //   dataIndex: "validStatusTxt",
  // },
  {
    title: "学习进度",
    align: "center",
    dataIndex: "studyProgress",
    scopedSlots: { customRender: "studyProgress" },
  },
  {
    title: "延期次数",
    align: "center",
    dataIndex: "delayCount",
  },
  {
    title: "订单编号",
    align: "center",
    dataIndex: "orderCode",
  },
  {
    title: "支付方",
    align: "center",
    dataIndex: "payer",
    scopedSlots: { customRender: "payer" },
  },
  {
    title: "已考试次数",
    align: "center",
    dataIndex: "haveExamCount",
  },
  {
    title: "最近一次考试总成绩",
    align: "center",
    dataIndex: "latestScore",
  },
  {
    title: "操作",
    align: "center",
    scopedSlots: { customRender: "operation" },
  },
];
const columns2 = [
  {
    title: "序号",
    width: "80px",
    align: "center",
    dataIndex: "index",
    scopedSlots: { customRender: "index" },
  },
  {
    title: "操作账号",
    align: "center",
    dataIndex: "code1",
  },
  {
    title: "操作",
    align: "center",
    dataIndex: "code2",
  },
  {
    title: "操作时间",
    align: "center",
    dataIndex: "code3",
  },
  {
    title: "备注",
    align: "center",
    dataIndex: "code4",
  },
];
const columns3 = [
  {
    title: "序号",
    width: "80px",
    align: "center",
    dataIndex: "index",
    scopedSlots: { customRender: "index" },
  },
  {
    title: "考试场次日期",
    align: "center",
    dataIndex: "examDate",
  },
  {
    title: "总成绩",
    align: "center",
    dataIndex: "totalScore",
  },
];
import HeaderBox from "@/components/HeaderBox.vue";

export default {
  // 可用组件的哈希表
  components: { HeaderBox },
  // 接收传值
  props: {},
  // 数据对象
  data() {
    return {
      // 其他课程弹框字段
      current: 1,
      elseCourseList: [],
      modelSearchList: [],
      courseName: "", // 搜索框

      name: null, // 关键字
      columns, // 表头
      columns2, //操作记录表头
      tableData: [], // 列表数据
      loading: false, // 列表加载
      total: 0, // 总数量
      pageNumber: 1, //页码
      pageSize: 10, // 页数
      elseCourseShow: false, // 其他课程弹框
      isValidityShow: false, // 有效期弹窗
      isRecordShow: false, // 操作记录弹窗
      PreventLoad: false, // 防抖状态
      columns3,
      achievementsShow: false, // 历史成绩
      isNumberShow: false, // 考试次数
      examNumForm:{ // 修改考生考试次数表单
        courseId: 0, // 课程id
        examCount: null,  // 考试次数
        remark: '',  // 备注
        userId: 0,  //用户id
      },
      achievementsList: [],
      // 课程
      courseData: [],
      courseId: "",
      validity: {
        courseId: 0, // 课程id
        oldStartTime: "", // 开始时间
        // modifier: 0, // 修改者
        oldEndTime: "", // 结束时间
        userId: 0, // 用户id
        type: 1, //type类型1.个人 2.班级
        courseName: "",
        startTime: "",
        endTime: "",
      },
      timedata: [],
      remark: null,
    };
  },
  // 事件处理器
  methods: {
    // 所有课程划入事件
    // 划入展示列表
    showAllCourse() {
      this.elseCourseShow = true;
    },

    // 其他课程弹框事件
    // 分页修改
    changeElseCourse(e) {
      this.elseCourseList = this.modelSearchList.slice((e - 1) * 30, e * 30);
    },
    // 模糊搜索
    filterCourseList() {
      let arr = this.courseData.filter((item) => {
        return item.courseName.indexOf(this.courseName) != -1;
      });
      this.modelSearchList = arr;
      this.changeElseCourse(1);
      this.current = 1;

      // this.getdataList()
    },
    // 重置
    reset() {
      this.courseName = "";
      this.filterCourseList();
    },

    // tab切换
    callback(e) {
      if (!e) {
        return;
      }
      this.pageNumber = 1;
      this.courseId = e;
      this.getdataList();
    },

    // 选择日期
    onChange(date, dateString) {
      this.timedata = dateString;
      this.validity.startTime = dateString[0];
      this.validity.endTime = dateString[1];
    },
    // 分页
    onPage(e) {
      // console.log(e);
      this.pageNumber = e.current;
      this.pageSize = e.pageSize;
      this.getdataList();
    },
    // 搜索
    search() {
      this.pageNumber = 1;
      // this.pageSize = 10;
      this.getdataList();
    },
    // 获取数据
    getdataList() {
      this.loading = true;
      this.$ajax({
        url: "/hxclass-management/course/user-course/personal-study",
        method: "get",
        params: {
          pageNum: this.pageNumber,
          pageSize: this.pageSize,
          keyword: this.name,
          courseId: this.courseId,
        },
      }).then((res) => {
        // console.log(res);
        this.loading = false;
        if (res.code == 200 && res.success) {
          this.tableData = res.data.records;
          this.total = res.data.total;
        }
      });
    },
    // 获取课程
    getcourse() {
      this.$ajax({
        url: "/hxclass-management/course/study-manage",
        method: "get",
        params: {
          courseName: this.courseName,
          classType: 1,
        },
      }).then((res) => {
        // console.log(res);
        if (res.code == 200 && res.success) {
          this.courseData = res.data;
          this.elseCourseList = res.data.slice(0, 30);
          this.modelSearchList = res.data;
          this.courseId = res.data[0].courseId;
          this.getdataList();
        }
      });
    },
    // 获取有效期
    getperiod(e) {
      // this.timedata[0] = e.startTime;
      // this.timedata[1] = e.endTime;

      this.$set(this.timedata, [0], e.startTime);
      this.$set(this.timedata, [1], e.endTime);

      // console.log(this.timedata);
      // 勿动
      for (let index = 0; index < this.courseData.length; index++) {
        if (this.courseId == this.courseData[index].courseId) {
          this.validity.courseName = this.courseData[index].courseName;
        }
      }
      // this.timedata = null;
      this.remark = null;
      this.isValidityShow = true;
      this.validity.oldStartTime = e.startTime;
      this.validity.oldEndTime = e.endTime;
      this.validity.userId = e.userId;
    },
    // 获取历史成绩
    getHistory(e) {
      // console.log(e);
      this.achievementsShow = true;
      this.$ajax({
        url:
          "/hxclass-management/exam/examinee/study/record?userId=" +
          e +
          "&courseId=" +
          this.courseId,
        method: "get",
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.achievementsList = res.data;
        }
      });
    },
    // 更改课程有效期
    selchange(e) {
      if (this.PreventLoad) {
        return;
      }
      if (!this.validity.startTime) {
        this.$message.warning("请填写有效期开始时间和结束时间");
        return;
      }
      if (!this.remark) {
        this.$message.warning("请填写备注");
        return;
      }
      this.validity.remark = this.remark;
      this.validity.courseId = this.courseId;
      this.PreventLoad = true;
      this.$ajax({
        url: "/hxclass-management/course/user-course/change-valid-time",
        method: "PUT",
        params: this.validity,
      }).then((res) => {
        this.PreventLoad = false;

        if (res.code == 200 && res.success) {
          this.isValidityShow = false;
          this.$message.success(res.message);
          this.getdataList();
        } else {
          this.isValidityShow = false;
          this.$message.success(res.message);
          this.getdataList();
        }
      });
    },

    // 打开考试次数弹窗
    onOpenExamModal(userId){
      this.isNumberShow = true
      this.examNumForm.userId = userId
    },

    // 修改考试次数
    onModify(){
      if(!this.examNumForm.examCount){
        this.$message.warning('请输入考试次数');
        return
      }
      if(!this.examNumForm.remark){
        this.$message.warning('请输入备注');
        return
      }
      this.examNumForm.courseId = this.courseId
      this.PreventLoad = true;

      this.$ajax({
        url: "/hxclass-management/course/updateExamCount",
        method: "POST",
        params: this.examNumForm,
      }).then((res) => {
        this.PreventLoad = false;

        if (res.code == 200 && res.success) {
          this.isNumberShow = false
          this.$message.success('修改成功');
          this.getdataList();
        } else {
          this.$message.warning(res.message);
        }
      });
    }
  },
  // 生命周期-实例创建完成后调用
  created() {
    // this.getdataList();
    this.getcourse();
  },
  // 生命周期-实例挂载后调用
  mounted() {},
  // 生命周期-实例销毁离开后调用
  destroyed() {},
  // 计算属性监听
  computed: {
    isElse() {
      let flag = false;
      this.courseData.slice(0, 4).map((item) => {
        if (item.courseId == this.courseId) {
          flag = true;
        }
      });
      return flag;
    },
  },
  // 自定义的侦听器
  watch: {
    isNumberShow(){
      if(!this.isNumberShow){
        setTimeout(()=>{
          this.examNumForm.examCount = null
          this.examNumForm.remark = ''
        },200)
      }
    }
  },
};
</script>

<style lang="less" scoped>
/deep/.ant-table-placeholder {
  z-index: 0;
}
// .allCourse{
//   position: fixed;
//   right: 50px;
//   bottom: 200px;
//   .text{
//     cursor: pointer;
//     box-sizing: border-box;
//     padding: 10px;
//     width: 38px;
//     height: auto;
//     border: 1px solid #d8d8d8;
//     box-shadow: 0 2px 9px 0 rgb(0 0 0 / 15%);
//     border-radius: 27.5px;
//     background: #fff;
//   }
//   // 其他课程弹框列表
//   .closeModel{
//     display: none;
//   }
//   .elseCourseModel{
//     width: 1200px;
//     background: #fff;
//     position: absolute;
//     z-index: 2;
//     right: 100px;
//     bottom: 0px;
//     padding: 50px;
//     border: 1px solid #ccc;
//     .header{
//       display: flex;
//       justify-content: space-between;
//       align-items: center;
//       position: relative;
//       .closeBtn{
//         position: absolute;
//         right: -25px;
//         top: -30px;
//         cursor: pointer;
//         // border: 1px solid #333;
//         // border-radius: 50%;
//         // padding: 5px;
//       }
//       .title{
//         font-weight: bold;
//       }
//       .search{
//         display: flex;
//       }
//     }
//     /deep/.ant-modal-content{
//       min-height: 444px;
//       position: relative;
//     }
//     .courseList{
//       margin-top: 30px;
//       display: flex;
//       flex-wrap: wrap;
//       li{
//         width: 30%;
//         margin-bottom: 10px;
//         overflow: hidden;
//         text-overflow: ellipsis;
//         white-space: nowrap;
//         margin-right: 3%;
//       }
//       li:nth-child(3n+3){
//         margin-right: 0;
//       }
//       li:hover{
//         color: @theme;
//         cursor: pointer;
//       }
//       .active{
//         color: @theme;
//       }
//     }
//     .ant-pagination{
//       position: absolute;
//       bottom: 24px;
//       right: 24px;
//       text-align: right;
//     }
//   }
// }
.btn {
  margin-left: 24px;
}

.none {
  width: 100%;
  margin: 80px 0;
  font-size: 24px;
  text-align: center;
}

.table-box {
  /deep/ .ant-tabs-bar {
    border-bottom: none;
  }
  .tab {
    display: flex;
    p {
      line-height: 45px;
      margin-left: 32px;
      cursor: pointer;
      white-space: nowrap;
    }
    .elseCourse {
      color: @theme;
    }
  }
}
.all_content_box {
  .line-item {
    margin-bottom: 20px;
    display: flex;
    .all_left_name {
      text-align: right;
      margin-right: 20px;
    }
  }
  .line-item:last-child {
    margin-bottom: 0;
  }
  .right {
    flex: 1;
    /deep/.ant-input {
      height: 40px;
    }
  }
  textarea.ant-input {
    flex: 1;
  }
}
/deep/ .ant-modal-footer {
  text-align: center;
}
.btn_router_link {
  display: flex;
}
.rows {
  margin-bottom: 10px;
  .left {
    line-height: 22px;
    &::before {
      content: "*";
      color: red;
      margin-right: 2px;
    }
    &::after {
      content: "：";
    }
  }
}
// 其他课程弹框列表 - tab点击model弹框样式
.elseCourseModel {
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .search {
      display: flex;
    }
  }
  /deep/.ant-modal-content {
    min-height: 444px;
    position: relative;
  }
  .courseList {
    display: flex;
    flex-wrap: wrap;
    li {
      width: 30%;
      margin-bottom: 10px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin-right: 3%;
    }
    li:nth-child(3n + 3) {
      margin-right: 0;
    }
    li:hover {
      color: @theme;
      cursor: pointer;
    }
    .active {
      color: @theme;
    }
  }
  .ant-pagination {
    position: absolute;
    bottom: 24px;
    right: 24px;
    text-align: right;
  }
}
</style>
